import React from "react"
import Layout from "../components/UI/Layout/Layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import oasLogo from '../data/images/oas-seal-vertical-slim.png'
export default() => {
return(
  <Layout>
    <Jumbotron className="rounded shadow jumbotron-fluid justify-content-center" >
      <Container >
        <Row className="text-center">
          <h2>Welcome to the Atlas of Competitive Intelligence for the Americas</h2>
        </Row>
        <Row fluid>
          <Col md={6} >

            <blockquote className="blockquote text-center">
              This is an alpha preview of a web application designed to share,
              discuss and llslasladsa... <br/>
              Our goal is to provide you with state of the art
              Economic research. For you to explore, share
              and discuss<br/>
              This first PILOT version of the site showcases:
              <strong >“Determinants of growth in the americas”</strong>
              <em>A multidisciplinary approach at economic indexes analysis</em>
            </blockquote>

          </Col>
          <Col md={4} className="so-2">
            <Image src={oasLogo} rounded fluid />
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  </Layout>
);
}
